import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { PurchaseService } from '../purchase.service';

@Component({
  selector: 'app-po-gen',
  templateUrl: './po-gen.component.html',
  styleUrls: ['./po-gen.component.css']
})
export class PoGenComponent implements OnInit {

  repmodel: any = {};
  DirectorList: any = [];
  DataForm: FormGroup;

  constructor(private hardfunc: HardFuncService,
              private _http: HttpClient,
              private fb: FormBuilder,
              private commonservice: CommonService,
              private POService: PurchaseService
  ) { }

  ngOnInit() {
    this.GetHandles();
    this.DataForm = this.CreateFormGroup();
  }
  
  SaveData(){
    console.log(this.DataForm.value);
    this.POService.POSave(this.DataForm.value)
      .subscribe((data: any) => {
        console.log(data);
      });
  }
  PurOrder(){
    this.POService.PurOrder(this.DataForm.get("Pono").value)
      .subscribe((data: any) => {
        console.log(data);
        data.map(d => {
          this.DataForm.get("PartyName").patchValue(d.Party_name);
          this.DataForm.get("Party_city").patchValue(d.Party_city);
          this.DataForm.get("Email").patchValue(d.Email);  
          this.DataForm.get("Pmst_cod").patchValue(d.PMST_COD);
          this.DataForm.get("Dir_id").patchValue(d.Dir_id);

          this.POService.PurOrder1(d.PONO)
            .subscribe((data1: any) => {
              console.log(data1);
              let index = 0;
              let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
              let formgroup = (formarray.at(index) as FormGroup);

              const formarr1 = new FormArray([]);
              data1.map(m => {
                formarr1.push(
                  this.fb.group({                  
                  Slip_no: m.Slip_no,
                  Itcode: m.ITCODE,
                  Qnty1: m.QNTY,
                  Unit1: m.UNIT,
                  Qnty2: m.Qnty2,
                  Unit2: m.Unit2,
                  Rate: m.RATE,
                  Unit: m.UNIT,
                  Disc: m.DISC,
                  Bsc: m.BSC,
                  Tax: m.TAX1,
                  Atax: m.ATAX1,
                  Exc: m.EXC1,
                  Ittot: m.ITTOT,                  
                })                
              );    
              index++              
              });
              this.DataForm.setControl("SubDatas", formarr1);              
            });
        });
      })
  }
  GetPartyDetail(){
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.hardfunc.Firmx )
    .subscribe(
        (data:any) => {
            var data1 = data[0];
            console.log(data1);
            this.DataForm.get("PartyName").patchValue(data1.PARTY_NAME.trim());
            this.DataForm.get("Party_city").patchValue(data1.PARTY_CITY.trim());
            this.DataForm.get("Email").patchValue(data1.EMAIL.trim());
        },
        err => {
            
        }
    )
  }
  GetHandles(){
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });

  }
  
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
  }
  DeleteRow(i: number) {    
    (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);          
  }
  CreateFormGroup() {
    return this.fb.group({      
      Pono: new FormControl(),
      Pmst_cod: new FormControl(''),
      PartyName: new FormControl(''),
      Party_city: new FormControl(''),
      Email: new FormControl(''),
      Podt: new FormControl(new Date()),
      Dir_id: new FormControl(),
      SubDatas: this.fb.array([this.CreateSubData()]),
    });
  }
  CreateSubData(): FormGroup {
    return this.fb.group({
      Srno: new FormControl(),
      Slip_no: new FormControl(),
      Itcode: new FormControl(),  
      ItemDescr: new FormControl(''),
      Qnty1: new FormControl(),
      Unit1: new FormControl(''),
      Qnty2: new FormControl(),
      Unit2: new FormControl(''),
      Rate: new FormControl(),
      Unit: new FormControl(''),
      Disc: new FormControl(),
      Bsc: new FormControl(),
      Tax: new FormControl(),
      Atax: new FormControl(),
      Exc: new FormControl(),
      Ittot: new FormControl(),
    });
  }
}
